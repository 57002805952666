// @import "~normalize.css";
@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Nunito:wght@500;600;700&display=swap";

// Global style

html {
  scroll-behavior: smooth;
}

body {
  font-family: Nunito, Helvetica, Arial, Lucida, sans-serif;
  padding: 0;
  margin: 0;
}

:root {
  --primary: #4866ff;
  --text: #666;
  --blue: #2ea3f2;
  --error: #f03;
}

.apos-rich-text {
  p {
    font-family: Nunito, Helvetica, Arial, Lucida, sans-serif;
    color: var(--text);
    margin: 0 0 1rem;
    font-size: 1.1rem;

    strong {
      font-weight: 700;
    }
  }
}

.apos-admin-bar-spacer {
  margin-bottom: 0 !important;
}

.title {
  font-family: Nunito, Helvetica, Arial, Lucida, sans-serif;
  font-weight: 500;
  font-size: 34px;
  line-height: 1.4em;
  color: var(--primary);
  margin: 0 0 30px;
  text-align: left;
}

.container {
  width: 80%;
  max-width: 1080px;
  margin: auto;
}

.btn {
  background: linear-gradient(90deg, #4968ff 0%, #6fe5f3 100%);
  font-family: Montserrat, Helvetica, Arial, Lucida, sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  padding: 15px 40px;
  color: #fff;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &::after {
    content: "";
    background: url("/images/arrow.svg");
    display: block;
    width: 20px;
    height: 10px;
    background-position: center;
    background-size: cover;
    margin-left: 5px;
  }

  a,
  a:visited {
    color: #fff;
    text-decoration: none;
  }
}

.page-container {
  padding-top: 75px;
}

.header {
  height: 75px;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 6px 7px -6px rgb(0 0 0 / 10%);
  z-index: 10;

  .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .logo {
    height: 60%;

    img {
      height: 100%;
    }
  }
}

.apos-notifications + [data-apos-refreshable] .header {
  top: 112px;
}

.footer {
  background-color: #222;
  padding: 15px 0;
  color: #fff;
  min-height: 52px;
  box-sizing: border-box;

  .container {
    height: 100%;
  }

  &__text {
    margin: 0;
  }

  &__link {
    color: #fff;
    text-decoration: none;
    font-weight: 700;
  }

  a,
  a:visited {
    color: #fff;
    text-decoration: none;
  }
}
