.list-widget {
  margin: 40px 0;

  &__list {
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 40px;
    row-gap: 30px;

    @media screen and (max-width: 980px) {
      grid-template-columns: repeat(1, 1fr);
      max-width: 550px;
      margin: 0 auto;
    }
  }

  &__item {
    list-style: none;
    padding-left: 43px;
    box-sizing: border-box;
    flex-shrink: 1;
  }

  &__item-title {
    position: relative;
    font-family: Nunito, Helvetica, Arial, Lucida, sans-serif;
    font-weight: 500;
    font-size: 34px;
    line-height: 1.4em;
    color: var(--primary);
    margin: 0 0 10px;
    text-align: left;

    &::before {
      content: "";
      position: absolute;
      left: -43px;
      top: 6px;
      display: block;
      width: 34px;
      height: 34px;
      margin-right: 10px;
      background-position: center;
      background-size: cover;
      fill: var(--primary);
      background-image: url("/images/circle-arrow.svg");
    }
  }
}
