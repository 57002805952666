.banner {
  position: relative;
  background-size: cover;
  background-position: 50%;
  color: #fff;
  height: calc(100vh - 75px);

  h1,
  h2 {
    font-family: Nunito, Helvetica, Arial, Lucida, sans-serif;
    margin-top: 0;
  }

  h1 {
    font-size: 80px;
    font-weight: 600;
    margin-bottom: 30px;
  }

  h2 {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 80px;
  }

  &__btn {
    font-size: 20px;
    text-decoration: none;
  }

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100px;
    background-color: #fff;
    clip-path: polygon(0 100%, 100% 0, 100% 100%);
  }
}
