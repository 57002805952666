.text-widget {
  margin: 40px 0;

  &__content {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 980px) {
      display: block;
    }
  }

  &__buttons {
    width: 36%;

    @media screen and (max-width: 980px) {
      margin-top: 30px;
      width: auto;
    }

    .btn {
      font-size: 16px;
      font-weight: 500;
      padding: 12px;
      margin-bottom: 30px;
      font-family: Nunito, Helvetica, Arial, Lucida, sans-serif;
    }
  }
}
