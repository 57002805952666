.contact-widget {
  margin: 40px 0;

  &__input {
    background-color: #eee;
    width: 100%;
    border-width: 0;
    border-radius: 0;
    color: #666;
    font-size: 14px;
    padding: 16px;
    margin-bottom: 30px;
    box-sizing: border-box;

    &:focus {
      outline: 1px solid var(--blue);
    }

    &.error {
      outline: 1px solid #f03;
    }
  }

  &__input-error {
    color: var(--error);
    position: absolute;
    font-size: 0.8rem;
    left: 0;
    bottom: 10px;
  }

  &__input-container {
    position: relative;
  }

  &__inputs {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 760px) {
      display: block;
    }

    .contact-widget__input-container {
      width: 100%;

      @media screen and (min-width: 760px) {
        &:first-child {
          margin-right: 15px;
        }

        &:last-child {
          margin-left: 15px;
        }
      }
    }
  }

  .textarea-input {
    width: 100%;
    min-height: 150px;
    resize: vertical;
  }

  &__submit-container {
    display: flex;
    justify-content: space-between;
  }

  &__btn-container {
    display: flex;
    align-items: center;

    label {
      color: #666;
    }

    .contact-widget__input {
      width: 60px;
      margin: 0 15px 0 5px;
    }
  }

  &__message-container {
    margin-right: 10px;
  }

  &__message {
    color: var(--blue);

    &.error {
      color: var(--error);
    }
  }
}
